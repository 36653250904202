<template>
  <div>
    <b-button
      v-if="status == 0"
      variant="danger"
      @click="deleteModal"
      :disabled="disabled.delete"
      class="mr-2"
      >Delete</b-button
    >
    <b-button
      v-if="status == 0 || status == 1"
      variant="primary"
      @click="informationModal"
      :disabled="disabled.edit"
      class="mr-2"
      >Edit</b-button
    >
    <b-button
      v-if="status == 2"
      variant="primary"
      @click="informationModal"
      :disabled="disabled.view"
      class="mr-2"
      >View</b-button
    >
    <b-modal
      v-model="showModal.information"
      centered
      no-close-on-backdrop
      scrollable
      title="Edit"
      id="void_target"
      ref="modal"
      size="lg"
    >
      <b-overlay :show="loading">
        <b-tabs v-model="tabs" content-class="mt-3 mb-10" lazy>
          <b-tab title="Information">
            <b-row class="mb-5">
              <b-col md="12" class="mt-1 mb-3">
                <h5 class="subtitle">Invoice</h5>
              </b-col>
              <b-col md="4" class="my-1">
                <b-form-group
                  class="invoice_no"
                  label-for="invoice_no"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Invoice No."
                >
                  <b-form-input
                    v-model="save.invoice_no"
                    type="text"
                    :placeholder="$t('page.please_choose_input')"
                    :disabled="status == 2"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4" class="my-1">
                <b-form-group
                  class="invoice_date"
                  label-for="invoice_date"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Invoice Date"
                >
                  <b-input-group>
                    <template v-slot:append>
                      <div
                        v-if="
                          save.invoice_date != null && save.invoice_date != ''
                        "
                        class="datepicker-x"
                        @click="initialTime(save, 'invoice_date')"
                      >
                        <b-icon-x font-scale="1.45"></b-icon-x>
                      </div>
                      <b-input-group-text>
                        <b-icon-calendar2-date></b-icon-calendar2-date>
                      </b-input-group-text>
                    </template>
                    <b-form-datepicker
                      v-model="save.invoice_date"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                      }"
                      :hide-header="true"
                      :disabled="status == 2"
                      placeholder="No date selected"
                      locale="de"
                    ></b-form-datepicker>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="4" class="my-1">
                <b-form-group
                  class="refundable"
                  label-for="refundable"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Tax Refund"
                >
                  <sv-select
                    v-model="save.refundable"
                    :placeholder="$t('page.please_choose')"
                    :options="options.refundable"
                    :reduce="options => options.value"
                    :selectable="options => !options.disabled"
                    :disabled="status == 2"
                    label="label"
                  >
                  </sv-select>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mt-5 mb-3">
                <h5 class="subtitle">Select Purchase</h5>
              </b-col>
              <b-col md="12" class="my-1">
                <b-form-group
                  class="refundable required"
                  label-for="refundable"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label-align-sm="right"
                  label-size="sm"
                  label="Purchase"
                >
                  <sv-select
                    @search="onSearch"
                    class="select-mh-9"
                    v-model="save.purchase_overview_id"
                    placeholder="Purchase ID、Order ID or Product Name"
                    :options="options.purchase_overview_id"
                    :disabled="status == 2"
                    multiple
                  >
                    <!-- <template slot="option" slot-scope="option">
                      {{ option }} 1
                    </template> -->
                    <template slot="selected-option" slot-scope="option">
                      {{ option.label.split('-')[0] }}
                    </template>
                  </sv-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Logs">
            <SimpleTable
              :items="items"
              :fields="fields"
              :tableId="'logs'"
              style="margin-top: 20px; min-height: 200px"
            ></SimpleTable>
          </b-tab>
        </b-tabs>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="disabled.submit"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()">
          {{ $t('page.cancel') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="showModal.delete"
      centered
      no-close-on-backdrop
      scrollable
      title="Please Confirm"
      id="please_confirm"
      ref="modal"
    >
      <b-overlay :show="loading2">
        Are you sure to delete this invoice?
      </b-overlay>

      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="confirm()"
          :disabled="disabled.confirm"
        >
          Confirm
        </b-button>
        <b-button variant="secondary" @click="close()">
          {{ $t('page.cancel') }}
        </b-button>
      </template></b-modal
    >
  </div>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import SimpleTable from '@/components/Table/Simple.vue';
// import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
export default {
  name: 'saasUploadManagement',
  data: function() {
    return {
      tabs: 0,
      showModal: {
        information: false,
        delete: false
      },
      disabled: {
        delete: false,
        edit: false,
        view: false,
        submit: false,
        confirm: false
      },
      save: {
        invoice_no: null,
        refundable: null,
        invoice_date: null,
        purchase_overview_id: null
      },
      loading: false,
      loading2: false,

      items: [],
      fields: [
        {
          key: 'created_at',
          label: 'Time'
        },
        {
          key: 'system_user',
          label: 'System User'
        },
        {
          key: 'log_action',
          label: 'Action'
        },
        {
          key: 'detail',
          label: 'Details'
        }
      ],

      options: {
        refundable: [
          { label: 'Yes', value: 1 },
          { label: 'No', value: 2 },
          { label: 'Uncertain', value: 3 }
        ],
        purchase_overview_id: []
      }
    };
  },
  components: { SimpleTable },
  props: ['id', 'initFn', 'status'],
  methods: {
    //   删除
    initialTime(obj, name) {
      obj[name] = null;
    },
    onSearch: function(search, loading) {
      if (search != null && search != '') {
        loading(true);
        this.search(loading, search, this);
      }
    },
    // 做放抖动处理
    search: _.debounce((loading, search, vm) => {
      apiBus.saasPuchaseInvoiceFile
        .purchaseInvoiceSupplierList({ keyWord: search })
        .then(data => {
          // console.log(data);
          vm.options.purchase_overview_id = data.data.data;
          loading(false);
        })
        .catch(error => {
          console.log(error);
          vm.options.purchase_overview_id = [];
          loading(false);
        });
    }, 350),

    informationModal() {
      this.showModal.information = true;
      this.loading = true;
      this.disabled.submit = true;
      apiBus.saasPuchaseInvoiceFile
        .getUploadPurchaseInvoiceFileInfo({ id: this.id })
        .then(data => {
          this.loading = false;
          this.disabled.submit = false;
          let {
            invoice_date,
            invoice_no,
            // purchase_overview_id,
            refundable,
            select
          } = data.data.data;
          this.save.invoice_date = invoice_date;
          this.save.invoice_no = invoice_no;
          this.save.purchase_overview_id = select;
          this.save.refundable = refundable == 0 ? null : refundable;
          this.options.purchase_overview_id = select ?? [];
          this.save.id = null;
        })
        .catch(error => {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
      apiBus.uploadInvoice
        .getPurchaseInvoiceFileLog({ id: this.id })
        .then(data => {
          this.items = data.data.data;
        })
        .catch(error => {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    submit() {
      if (this.save.purchase_overview_id.length == 0) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        return false;
      }
      this.loading = true;
      this.disabled.submit = true;
      let purchase_overview_id_arr = [];
      let data = {
        id: this.id,
        invoice_no: this.save.invoice_no,
        refundable: this.save.refundable,
        invoice_date: this.save.invoice_date
      };
      this.save.purchase_overview_id.forEach(element => {
        purchase_overview_id_arr.push(element.value);
      });

      data.fulfillment_order_item_id = purchase_overview_id_arr;

      apiBus.saasPuchaseInvoiceFile
        .saveUploadPurchaseInvoiceDetail(data)
        .then(data => {
          console.log(data);
          this.showModal.information = false;
          this.loading = false;
          this.disabled.submit = false;
          this.initFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch(error => {
          // this.showModal.information = false;
          this.loading = false;
          this.disabled.submit = false;
          // this.initFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    deleteModal() {
      this.showModal.delete = true;
    },
    confirm() {
      this.disabled.confirm = true;
      this.loading2 = true;
      apiBus.uploadInvoice
        .deletePurchaseInvoiceFile({ id: this.id })
        .then(data => {
          console.log(data);
          this.showModal.delete = false;
          this.disabled.confirm = false;
          this.loading2 = false;

          this.initFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully Deleted!' // 提示内容
          });
        })
        .catch(error => {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    init() {}
  },
  watch: {},
  computed: {
    ...mapState({
      baseConfig: state => state.baseconfig.baseconfig
    })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
